// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_Trash__2LxlC {\n    color: #673ab7;\n    cursor: pointer;\n    transition: 0.25s;\n}\n.style_Trash__2LxlC:hover {\n    color: #616161;\n    cursor: pointer;\n    transition: 0.25s;\n}\n", "",{"version":3,"sources":["webpack://src/ui-component/Table/style.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".Trash {\n    color: #673ab7;\n    cursor: pointer;\n    transition: 0.25s;\n}\n.Trash:hover {\n    color: #616161;\n    cursor: pointer;\n    transition: 0.25s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Trash": "style_Trash__2LxlC"
};
export default ___CSS_LOADER_EXPORT___;
