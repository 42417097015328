import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import config from '../../../config';
import CommonTable from 'ui-component/Table/CommonTable';
import axios from 'axios';
import './styles.css';

let tableData = [];
const column = ['Sl. No', 'Username', 'gender', 'Bot Status', 'Bot can play'];

const AllBots = () => {
    const token = localStorage.getItem('accessToken');
    const [loading, setLoading] = useState(true);
    const [bots, setBots] = useState([]);
    const [isUpdateTable, setIsUpdateTable] = useState(false);
    const [searches, setSearches] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        setLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`
        };
        axios
            .get(`${config.baseUrl}/api/bot`, {
                headers
            })
            .then((data) => {
                setLoading(false);
                setBots(data.data.bots);
            })
            .catch((err) => console.log(err));
    }, [isUpdateTable]);

    const data = isSearch ? searches : bots;

    if (data.length > 0) {
        tableData = data.map(({ _id, ...user }, index) => ({
            sl: index + 1,
            username: user?.username,
            gender: user?.gender,
            botStatus: user?.status,
            botCanPlay: user?.botCanPlay
        }));
    }

    return (
        <>
            <div>
                <MainCard title="Bot Players">
                    <SubCard title={loading ? <CircularProgress color="secondary" /> : ' '}>
                        {bots.length <= 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: 1,
                                    m: 1,
                                    bgcolor: 'background.paper',
                                    borderRadius: 1
                                }}
                            >
                                <CircularProgress color="secondary" />
                            </Box>
                        ) : (
                            <div className="table-container">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Sl. No</th>
                                            <th style={{ width: '30%' }}>Username</th>
                                            <th style={{ width: '20%' }}>Gender</th>
                                            <th style={{ width: '20%' }}>Bot Status</th>
                                            <th style={{ width: '20%' }}>Bot can play</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row) => (
                                            <tr key={row.sl}>
                                                <td>{row.sl}</td>
                                                <td>{row.username}</td>
                                                <td>{row.gender}</td>
                                                <td>{row.botStatus}</td>
                                                <td>{row.botCanPlay}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </SubCard>
                </MainCard>
            </div>
        </>
    );
};

export default AllBots;
