// assets
import { IconPalette, IconShadow, IconTypography, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'master',
            title: 'Master',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                // {
                //     id: 'transaction',
                //     title: 'Transaction',
                //     type: 'item',
                //     url: '/transaction',
                //     target: true
                // },
                {
                    id: 'PageSlider',
                    title: 'Banner',
                    type: 'item',
                    url: '/master/banner',
                    breadcrumbs: false
                },
                // {
                //     id: 'AppVersion',
                //     title: 'App Version',
                //     type: 'item',
                //     url: '/master/app-version',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'ChatItem',
                //     title: 'Chat Item',
                //     type: 'item',
                //     url: '/master/chat-item',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'GiftItem',
                //     title: 'Gift Item',
                //     type: 'item',
                //     url: '/master/gift-item',
                //     breadcrumbs: false
                // },
                {
                    id: 'state',
                    title: 'State',
                    type: 'item',
                    url: '/master/state',
                    breadcrumbs: false
                },
                {
                    id: 'games',
                    title: 'Games',
                    type: 'item',
                    url: '/master/games',
                    breadcrumbs: false
                }
                // {
                //     id: 'tournament-category',
                //     title: 'Tournament category',
                //     type: 'item',
                //     url: '/master/tournament-category',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'Tournaments',
                //     title: 'Tournaments',
                //     type: 'item',
                //     url: '/master/tournaments',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'global-configuration',
                //     title: 'Global Configuration',
                //     type: 'item',
                //     url: '/master/global-configuration',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'withdrawl-settings',
                //     title: 'Withdrawl Settings',
                //     type: 'collapse',
                //     children: [
                //         {
                //             id: 'Admin Withdrawl settings',
                //             title: 'Admin Withdrawl settings',
                //             type: 'item',
                //             url: '/master/withdrawl/admin-settings',
                //             breadcrumbs: false
                //         },
                //         {
                //             id: 'Frontend settings',
                //             title: 'Frontend settings',
                //             type: 'item',
                //             url: '/master/withdrawl/frontend-settings',
                //             breadcrumbs: false
                //         }
                //     ]
                // },
                // {
                //     id: 'club-types',
                //     title: 'Club Types',
                //     type: 'item',
                //     url: '/master/club-types',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'RpsSetting',
                //     title: 'RPS Setting',
                //     type: 'item',
                //     url: '/master/rps-setting',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'GatewaysSettings',
                //     title: 'Gateways Settings',
                //     type: 'collapse',
                //     children: [
                //         {
                //             id: 'payment gateway',
                //             title: 'Payment Gateway',
                //             type: 'item',
                //             url: '/master/gateways-settings/payment-gateway',
                //             breadcrumbs: false
                //         },
                //         {
                //             id: 'sms gateway',
                //             title: 'SMS Gateway',
                //             type: 'item',
                //             url: '/master/gateways-settings/sms-gateway',
                //             breadcrumbs: false
                //         }
                //     ]
                // }
            ]
        },
        {
            id: 'players',
            title: 'Players',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'real-players',
                    title: 'Real Players',
                    type: 'item',
                    url: '/players/real-players',
                    breadcrumbs: false
                },
                {
                    id: 'all-bots',
                    title: 'All Bots',
                    type: 'item',
                    url: '/players/all-bots',
                    breadcrumbs: false
                },
                // {
                //     id: 'avtars',
                //     title: 'Avtars',
                //     type: 'item',
                //     url: '/players/avtars',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'bot-settng',
                //     title: 'Bot Setting',
                //     type: 'item',
                //     url: '/players/bot-setting',
                //     breadcrumbs: false
                // },
                {
                    id: 'deposite-transaction',
                    title: 'Transactions',
                    type: 'item',
                    url: '/transactions',
                    breadcrumbs: false
                }
                // {
                //     id: 'withdrawl-request',
                //     title: 'Withdrawl Request',
                //     type: 'item',
                //     url: '/players/withdrawl-request',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'live-tournaments',
            title: 'Live Tournaments',
            type: 'item',
            url: '/live-tournaments',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'leaderboard',
            title: 'Leaderboard',
            type: 'item',
            url: '/leaderboard',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        // {
        //     id: 'feedbacks',
        //     title: 'Feedbacks',
        //     type: 'collapse',
        //     icon: icons.IconShadow,
        //     children: [
        //         {
        //             id: 'reported-problems',
        //             title: 'Reported Problems',
        //             type: 'item',
        //             url: '/feedbacks/reported-problems',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'testimonials',
        //             title: 'Testimonials',
        //             type: 'item',
        //             url: '/feedbacks/testimonials',
        //             breadcrumbs: false
        //         }
        //     ]
        // },

        // {
        //     id: 'promotion-system',
        //     title: 'Promotion System',
        //     type: 'collapse',
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: 'bonus-system',
        //             title: 'Bonus System',
        //             type: 'item',
        //             url: '/promotion-system/bonus-system',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'Offer Notification',
        //             title: 'offer-notification',
        //             type: 'item',
        //             url: '/promotion-system/offer-notification',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'real-tournaments',
        //     title: 'Real Tournaments',
        //     type: 'item',
        //     url: '/real-tournaments',
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // }
        {
            id: 'game-play',
            title: 'Game Play',
            type: 'collapse',
            icon: icons.IconShadow,
            children: [
                {
                    id: 'bonus-system',
                    title: 'Gameplay Data',
                    type: 'item',
                    url: '/game-play/data',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default utilities;
