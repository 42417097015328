// project imports
import { Navigate } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AllBots from 'views/player/AllBots/Index';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing

// sample page routing
const Wallet = Loadable(lazy(() => import('views/wallet')));
const RealPlayer = Loadable(lazy(() => import('views/player/realPlayer/Index')));
const Avatar = Loadable(lazy(() => import('views/player/avatar')));
const WithdrawalRequest = Loadable(lazy(() => import('views/player/withdrawalRequest')));
const BotSetting = Loadable(lazy(() => import('views/player/botSetting')));
const AddState = Loadable(lazy(() => import('views/master/States/index')));
// const AppVersion = Loadable(lazy(() => import('views/master/appVersion')));
const ChatItem = Loadable(lazy(() => import('views/master/chatItem')));
const GiftItem = Loadable(lazy(() => import('views/master/giftItem')));
const LiveTournaments = Loadable(lazy(() => import('views/liveTournaments')));
const RealTournaments = Loadable(lazy(() => import('views/realTournaments')));
const GamePlayData = Loadable(lazy(() => import('views/gamePlayData')));
const TournamentCategory = Loadable(lazy(() => import('views/master/tournament-category/index')));
const Tournament = Loadable(lazy(() => import('views/master/tournament/index')));
const ClubTypes = Loadable(lazy(() => import('views/master/clubTypes')));
const RPSSetting = Loadable(lazy(() => import('views/master/rpsSetting')));
const ReportedProblem = Loadable(lazy(() => import('views/feedBack/reportedProblems/ReportedProblems')));
const Testimonials = Loadable(lazy(() => import('views/feedBack/testimonials/Testimonials')));
const AddTestimonial = Loadable(lazy(() => import('views/feedBack/testimonials/AddTestimonial')));
const GlobalConfigaretion = Loadable(lazy(() => import('views/master/globalConfigaretion/GlobalConfigaretion')));
const Game = Loadable(lazy(() => import('views/master/Game/index')));
const AdminWithdrawl = Loadable(lazy(() => import('views/master/Withdraw-setting/AdminSetting')));
const FrontendSetting = Loadable(lazy(() => import('views/master/Withdraw-setting/frontendSetting')));
const EditTournament = Loadable(lazy(() => import('views/master/tournament/EditTournament')));
const ViewInfo = Loadable(lazy(() => import('views/master/tournament/ViewInfo')));
const ViewReportedProblem = Loadable(lazy(() => import('views/feedBack/reportedProblems/ViesReportedProblem')));
const PageSlider = Loadable(lazy(() => import('views/master/pageSlider/Index')));
const OfferNotification = Loadable(lazy(() => import('views/promotion-system/offer-notification/Index')));
const BonusSystem = Loadable(lazy(() => import('views/promotion-system/bonusSystem')));
const AddNotification = Loadable(lazy(() => import('views/promotion-system/offer-notification/AddNotification')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Transactions = Loadable(lazy(() => import('views/master/transactions/index')));
const Leaderboard = Loadable(lazy(() => import('views/leaderboard/index')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    exact: true,
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/master/state',
            element: <AddState />
        },
        {
            path: '/transactions',
            element: <Transactions />
        },
        // {
        //     path: '/master/app-version',
        //     element: <AppVersion />
        // },
        {
            path: '/master/chat-item',
            element: <ChatItem />
        },
        {
            path: '/master/gift-item',
            element: <GiftItem />
        },
        {
            path: '/master/games',
            element: <Game />
        },
        {
            path: '/master/tournament-category',
            element: <TournamentCategory />
        },
        {
            path: '/master/tournaments',
            element: <Tournament />
        },
        {
            path: '/master/club-types',
            element: <ClubTypes />
        },
        {
            path: '/master/rps-setting',
            element: <RPSSetting />
        },
        {
            path: '/master/tournaments/edit/:id',
            element: <EditTournament />
        },
        {
            path: '/master/tournaments/viewInfo/:id',
            element: <ViewInfo />
        },
        {
            path: '/master/global-configuration',
            element: <GlobalConfigaretion />
        },
        {
            path: '/master/withdrawl/admin-settings',
            element: <AdminWithdrawl />
        },
        {
            path: '/master/withdrawl/frontend-settings',
            element: <FrontendSetting />
        },
        {
            path: '/master/banner',
            element: <PageSlider />
        },
        {
            path: '/players/real-players',
            element: <RealPlayer />
        },
        {
            path: '/players/all-bots',
            element: <AllBots />
        },
        {
            path: '/players/avtars',
            element: <Avatar />
        },
        {
            path: '/deposite/wallet',
            element: <Wallet />
        },
        {
            path: '/players/withdrawl-request',
            element: <WithdrawalRequest />
        },
        {
            path: '/players/bot-setting',
            element: <BotSetting />
        },
        {
            path: '/live-tournaments',
            element: <LiveTournaments />
        },
        {
            path: '/feedbacks/reported-problems',
            element: <ReportedProblem />
        },
        {
            path: '/feedbacks/reported-problems/view-reported-problem/:id',
            element: <ViewReportedProblem />
        },
        {
            path: '/feedbacks/testimonials',
            element: <Testimonials />
        },
        {
            path: '/feedbacks/add-testimonial',
            element: <AddTestimonial />
        },
        {
            path: '/promotion-system/bonus-system',
            element: <BonusSystem />
        },
        {
            path: '/promotion-system/offer-notification',
            element: <OfferNotification />
        },
        {
            path: '/promotion-system/offer-notification/add',
            element: <AddNotification />
        },
        {
            path: '/real-tournaments',
            element: <RealTournaments />
        },
        {
            path: '/game-play/data',
            element: <GamePlayData />
        },
        {
            path: '/leaderboard',
            element: <Leaderboard />
        }
    ]
};

export default MainRoutes;
